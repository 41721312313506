import { Button_1 } from "./components/base/button/button.jsx";
import * as React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Grid, Paper } from "@mui/material";
import leftCard from "../assets/leftCard.png";
import rightCard from "../assets/rightCard.png";
import CreatureImage from "../assets/mainCreature.png";
import barBackground from "../assets/topBarBox.png";
import footer from "../assets/footer.png";
import videoPrev from "../assets/videoPrev.jpg";
import video from "../assets/TCGVideo.mp4";
import socialBadge from "../assets/socialBadge.png";
import logo from "/assets/logo.png";
import xLogo from "/assets/xLogo.png";
import discordLogo from "/assets/discordLogo.png";
import icpLogo from "/assets/icpLogo.png";
import boomLogo from "/assets/boomLogo.png";
import background from "/assets/background.png";
const root = ReactDOM.createRoot(document.getElementById("root"));
const ElementumWebsite = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { container: true, sx: { padding: "3% 8% 0 8%", backgroundImage: "url(" + background + ")" } },
            React.createElement(Grid, { item: true, container: true, xs: 12, sx: { position: "fixed", height: "12vh", zIndex: 10 } },
                React.createElement(Paper, { sx: { backgroundColor: "transparent", backgroundImage: `url(${barBackground})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", width: "100%", minHeight: "12vh" }, elevation: 0 },
                    React.createElement(Grid, { container: true, sx: { width: "100%", height: "100%" } },
                        React.createElement(Grid, { item: true, xs: 4, sx: { width: "100%", height: "100%", paddingTop: "2.8vh" } },
                            React.createElement("img", { src: logo, alt: "Logo", style: { width: "38%", height: "auto", paddingLeft: "4vw", margin: 0 } })),
                        React.createElement(Grid, { item: true, xs: 5 },
                            React.createElement(Grid, { container: true, sx: { width: "100%", height: "100%", paddingTop: "4vh", fontSize: "1.2vw" } },
                                React.createElement(Grid, { item: true, xs: 4, sx: { width: "100%", height: "100%" } },
                                    React.createElement("a", { href: "https://guilds.boomdao.xyz/", target: "_blank", style: { textDecoration: "none" } },
                                        React.createElement("div", { style: { fontSize: "1.5em", color: "#fff", fontFamily: "Iceland", fontStyle: "bold", fontWeight: "400px", lineHeight: "normal" } }, "Airdrop"))),
                                React.createElement(Grid, { item: true, xs: 4, sx: { width: "100%", height: "100%" } },
                                    React.createElement("a", { href: "https://toniq.io/marketplace/faceted-meninas", target: "_blank", style: { textDecoration: "none" } },
                                        React.createElement("div", { style: { fontSize: "1.5em", color: "#fff", fontFamily: "Iceland", fontStyle: "bold", fontWeight: "400px", lineHeight: "normal" } }, "Meninas"))),
                                React.createElement(Grid, { item: true, xs: 4, sx: { width: "100%", height: "100%" } },
                                    React.createElement("a", { href: "https://toniq.io/marketplace/interitus", target: "_blank", style: { textDecoration: "none" } },
                                        React.createElement("div", { style: { fontSize: "1.5em", color: "#fff", fontFamily: "Iceland", fontStyle: "bold", fontWeight: "400px", lineHeight: "normal" } }, "Interitus")))))))),
            React.createElement(Grid, { item: true, container: true, xs: 12, sx: { height: "70vh", margin: "10vh" } },
                React.createElement(Grid, { item: true, container: true, xs: 6, sx: { padding: "5vh" } },
                    React.createElement(Grid, { item: true, container: true, xs: 12, alignItems: "end" },
                        React.createElement("p", { style: { fontSize: "1.7em", color: "#fff", fontFamily: "Inter", fontStyle: "bold", fontWeight: "400px", lineHeight: "normal" } }, "Build your deck and dominate.")),
                    React.createElement(Grid, { item: true, xs: 12, sx: { maxWidth: "1vw" } },
                        React.createElement(Button_1, { className: "boton-descargar-instance", property1: "frame-7" }))),
                React.createElement(Grid, { item: true, container: true, xs: 6, sx: { padding: "5vh", justifyContent: "center", textAlign: "center" } },
                    React.createElement("img", { src: CreatureImage, alt: "Creature", style: { margin: 0, minHeight: "125%" } }))),
            React.createElement(Grid, { item: true, container: true, xs: 12, sx: { marginTop: "30vh", zIndex: "1" } },
                React.createElement(Grid, { item: true, xs: 3 },
                    React.createElement("img", { src: leftCard, alt: "Creature", style: { marginLeft: -150, minHeight: "130%" } })),
                React.createElement(Grid, { item: true, container: true, xs: 6, justifyContent: "center" },
                    React.createElement("video", { width: "80%", height: "75%", poster: videoPrev, controls: true, style: { zIndex: 0, margin: "10 0 0 10", borderRadius: "31.951px", border: "7.278px solid var(--borde-cobre, #755A3E)", boxShadow: "0px 9.704px 14.798px 0px rgba(0, 0, 0, 0.50)", backgroundColor: "black" } },
                        React.createElement("source", { src: video, type: "video/mp4" }),
                        "Your browser does not support the video tag.")),
                React.createElement(Grid, { item: true, xs: 3 },
                    React.createElement("img", { src: rightCard, alt: "Creature", style: { marginTop: 50, minHeight: "130%" } }))),
            React.createElement(Grid, { item: true, container: true, xs: 12, justifyContent: "center", sx: { zIndex: "0", width: "80vw", height: "35vh", marginTop: 0 } },
                React.createElement(Paper, { sx: { backgroundColor: "transparent", backgroundImage: `url(${footer})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", width: "80vw", height: "100vh", maxHeight: "100%" }, elevation: 0 },
                    React.createElement(Grid, { container: true },
                        React.createElement(Grid, { item: true, container: true, xs: 12, justifyContent: "center", style: { marginTop: 8 } },
                            React.createElement("p", { style: { color: "#2B3244", fontFamily: "Iceland", fontSize: "26.281px", fontStyle: "bold", fontWeight: "400px", lineHeight: "normal" } }, "Elementum \u00AE 2023")),
                        React.createElement(Grid, { item: true, container: true, xs: 12, spacing: 2 },
                            React.createElement(Grid, { item: true, container: true, xs: 6, justifyContent: "right" },
                                React.createElement("a", { href: "https://twitter.com/elementumone" },
                                    React.createElement("div", { className: "icon-x-grande-screen" },
                                        React.createElement("div", { className: "overlap-group", style: { backgroundImage: `url(${socialBadge})` } },
                                            React.createElement("img", { className: "vector", alt: "x-logo", src: xLogo, style: { top: "20px" } }))))),
                            React.createElement(Grid, { item: true, container: true, xs: 6, justifyContent: "left" },
                                React.createElement("a", { href: "https://discord.gg/Vses99CepK" },
                                    React.createElement("div", { className: "icon-x-grande-screen" },
                                        React.createElement("div", { className: "overlap-group", style: { backgroundImage: `url(${socialBadge})` } },
                                            React.createElement("img", { className: "vector", alt: "discord-logo", src: discordLogo, style: { top: "23px" } }))))),
                            React.createElement(Grid, { item: true, container: true, justifyContent: "center", xs: 12, spacing: 2, style: { marginTop: 3 } },
                                React.createElement("p", { style: { color: "#2B3244", fontFamily: "Iceland", fontSize: "16.281px", fontStyle: "bold", fontWeight: "400px", lineHeight: "normal" } }, "Powered By")),
                            React.createElement(Grid, { item: true, container: true, xs: 12, spacing: 2, style: { paddingTop: 0 } },
                                React.createElement(Grid, { item: true, container: true, xs: 12, justifyContent: "center", style: { paddingTop: 0 } },
                                    React.createElement("a", { href: "https://internetcomputer.org/" },
                                        React.createElement("img", { src: icpLogo, alt: "icp-logo", style: { margin: 0, minHeight: "100%", paddingTop: 0 } }))),
                                React.createElement(Grid, { item: true, container: true, xs: 12, justifyContent: "center", style: { paddingTop: 0 } },
                                    React.createElement("a", { href: "https://boomdao.xyz/" },
                                        React.createElement("img", { src: boomLogo, alt: "boom-logo", style: { margin: 0, minHeight: "100%", paddingTop: 0 } })))))))))));
};
root.render(React.createElement(ElementumWebsite, null));
