import PropTypes from "prop-types";
import React from "react";
import "./style.css";
import Download from "../../../../assets/download.svg";
export const Button_1 = ({ property1, className }) => {
    return (React.createElement("a", { href: "https://2jt6v-4aaaa-aaaal-ai4hq-cai.raw.icp0.io", target: "_blank", style: { textDecoration: "none" }, rel: "noreferrer" },
        React.createElement("div", { className: `boton-descargar ${property1} ${className}`, style: { maxWidth: "90%", height: "28%" } },
            React.createElement("div", { className: "frame" },
                React.createElement("div", { className: "div-wrapper" },
                    React.createElement("div", { className: "text-wrapper", style: { fontSize: "1.3em", color: "#fff", fontFamily: "Iceland", fontStyle: "bold", fontWeight: "400px", lineHeight: "normal" } }, "Play now"))))));
};
Button_1.propTypes = {
    property1: PropTypes.oneOf(["variant-2", "frame-7"]),
};
